/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter, Resize, ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { Query } from '@syncfusion/ej2-data';

// import axios from 'axios';
import { Header } from '.';
import { Methods } from '../pages/Methods';
import { langData } from '../data/dummy';

import '../pages/Zackat.css';

const Recordings = () => {
  const usr = JSON.parse(localStorage.getItem('user'));
  const toolbarOptions = ['Search', 'Add', 'Delete'];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const selectionSettings = { mode: 'Row', type: 'Single' };
  const valuerules = { required: true };
  const fmtData = [
    { value: 'MP3', id: 'mp3' },
    { value: 'PCM', id: 'pcm' },
    { value: 'WAV', id: 'wav' },
  ];
  let typeData = [
    { value: 'User', id: 'user' },
  ];

  const orgData = [];
  const start = (e) => {
    const audio = new Audio(`${REACT_APP_API_ENDPOINT}/audiodown?photo=${e.target.id}.mp3`);
    audio.play();
  };
  const startWav = (e) => {
    const audio = new Audio(`${REACT_APP_API_ENDPOINT}/audiodown?photo=${e.target.id}.wav`);
    audio.play();
  };
  const startPCM = (e) => {
    const audio = new Audio(`${REACT_APP_API_ENDPOINT}/audiodown?photo=${e.target.id}.pcm`);
    audio.play();
  };
  const recordPlayTemplate = (props) => (
    <div>
      {(props.format === 'pcm') ? (
        <input type="button" name="download" id={props._id} value="&#x23F5;" onClick={startPCM} />
      ) : (props.format === 'wav') ? (
        <input type="button" name="download" id={props._id} value="&#x23F5;" onClick={startWav} />
      ) : (
        <input type="button" name="download" id={props._id} value="&#x23F5;" onClick={start} />
      )}
    </div>
  );
  const editing = {
    allowEditing: false,
    allowAdding: true,
    allowDeleting: true,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  const fmtParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: fmtData,
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  const langParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: langData,
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };

  const { loading, data } = Methods('recordings', 'GET', '');
  let grid = useRef(null);
  const actionBegin = (args) => {
    if (grid) {
      const cols = grid.columns;
      for (const col of cols) {
        if (args.requestType === 'add') {
          if (col.headerText === 'ID' || col.headerText === 'Translation') {
            col.visible = false;
          } else {
            col.visible = true;
          }
        } else if (col.headerText === 'ID') {
          col.visible = false;
        } else {
          col.visible = true;
        }
      }
    }
  };
  const actionComplete = (args) => {
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data && args.data.name && args.data.tts) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });
        langData.forEach((element) => {
          if (element.value == args.data.language) {
            element.language = element.id;
          }
        });
        fmtData.forEach((element) => {
          if (element.value == args.data.format) {
            element.format = element.id;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/recordings`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
              args.data.tts = responseJson.tts;
              const rowInfo = args.data;
              grid.setRowData(responseJson._id, rowInfo.rowData);
              args.cancel = true;
              grid.endEdit();
              window.location.reload();
            }
          })
          .catch((error) => {
            alert('An error has occured during Insert!');
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/recordings`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            alert('An error has occured during Delete!');
          });
      } else {
        args.cancel = false;
      }
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.SystemType) {
    console.log('Recordings', data);
    typeData = data.SystemType;
  }
  const typeParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: typeData,
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  console.log('Recordings ', data);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Recordings" />
      <GridComponent
        dataSource={data.recordings}
        width="auto"
        allowPaging
        allowSorting
        editSettings={editing}
        toolbar={toolbarOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        selectionSettings={selectionSettings}
        enableHover={false}
        pageSettings={{ pageSize: 25, pageCount: 10 }}
        allowResizing
        allowFiltering
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ColumnDirective
            field="_id"
            headerText="ID"
            template={recordPlayTemplate}
            width="100"
            isPrimaryKey
            visible
          />
          <ColumnDirective
            field="name"
            headerText="Name"
            width="150"
            validationRules={valuerules}
            visible
          />
          <ColumnDirective
            field="tts"
            headerText="TTS"
            width="150"
            validationRules={valuerules}
            visible
          />
          <ColumnDirective
            field="translation"
            headerText="Translation"
            width="150"
            visible
          />
          <ColumnDirective
            field="format"
            headerText="Format"
            width="80"
            validationRules={valuerules}
            visible
            editType="dropdownedit"
            edit={fmtParams}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={fmtParams.params.dataSource}
          />
          <ColumnDirective
            field="systype"
            headerText="Type"
            width="150"
            validationRules={valuerules}
            visible
            editType="dropdownedit"
            edit={typeParams}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={typeParams.params.dataSource}
          />
          <ColumnDirective
            field="language"
            headerText="Language"
            width="150"
            validationRules={valuerules}
            visible
            editType="dropdownedit"
            edit={langParams}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={langParams.params.dataSource}
          />
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="150"
            textAlign="Center"
            editType="dropdownedit"
            edit={organizations}
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, Resize, ForeignKey]} />
      </GridComponent>
    </div>
  );
};
export default Recordings;
